import "./handlers/quantity-cart";
import "./handlers/sticky_header";

//Select function for mobile shop category change
const selectShopCategory = document.querySelector("#product_cat");
if (selectShopCategory) {
    selectShopCategory.addEventListener("change", (event) => {
        if (event.target.value === "0") {
            window.location = "/shop/";
        } else {
            window.location = "/?product_cat=" + event.target.value;
        }
    });
}

/* Styling class for WooCommerce shop category all */
const allShopCategory = document.querySelector(".product-categories-list .cat-item-all");

if ( document.body.classList.contains("archive") && document.body.classList.contains("woocommerce-shop") && allShopCategory ) {
    allShopCategory.classList.add("current-cat");
} else if ( document.body.classList.contains("archive") && document.body.classList.contains("woocommerce") && allShopCategory ) {
    allShopCategory.classList.remove("current-cat");
}

//Prevent clicking title
const productTitles = document.querySelectorAll(".woocommerce-LoopProduct-link.woocommerce-loop-product__link");
productTitles.forEach( (productTitle) => {
    productTitle.addEventListener("click", (event) => {
        event.preventDefault();
    })
})


// Prevent clicking thumbnail and title in the shop side cart
const shopSideCart = document.querySelector(".uael-mc-offcanvas");

window.onload = function() {
    let links = shopSideCart.getElementsByTagName('a');
    for (let i=0; i < links.length; i++) {
        if (links[i].classList.length > 0) {
            //do nothing
        } else {
            links[i].onclick = function() {
                return false;
            }
        }
    }
}

//Prevent clicking thumbnail and title in the cart
const productCartThumbnails = document.querySelectorAll(".eael-woo-cart-table .product-thumbnail a");
const productCartTitles = document.querySelectorAll(".eael-woo-cart-table .product-name a");

productCartThumbnails.forEach( (productCartThumbnail) => {
    productCartThumbnail.addEventListener("click", (event) => {
        event.preventDefault();
    })
})

productCartTitles.forEach( (productCartTitle) => {
    productCartTitle.addEventListener("click", (event) => {
        event.preventDefault();
    })
})