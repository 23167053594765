"use strict";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

gsap.to(".sticky_shop_header", {
    scrollTrigger: {
        trigger: ".products.eael-post-appender",
        start: "top top",
        end: "top top",
        toggleActions: "play none reverse none"
    } ,
    top: 0
});



