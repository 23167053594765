"use strict";

jQuery(function ($) {

  // Quantity "plus" and "minus" buttons
  $(document.body).on("click", ".eael-cart-qty-plus, .eael-cart-qty-minus", function () {
    var $qty = $(this).closest(".quantity").find(".qty");

    function quantity_upd() {
      $(".woocommerce-cart-form").on("change", ".qty", function () {
        $("[name='update_cart']").removeAttr("disabled");
        $("[name='update_cart']").trigger("click");
      });
    }

    // Trigger change event
    $qty.trigger("change", quantity_upd());
  });
});
